import React, { useEffect, useRef, useState } from "react";
import map2 from "../Assets/map2.png";
import map3 from "../Assets/map3.png";
import map4 from "../Assets/map4.png";
import map5 from "../Assets/map5.png";
import partylawn from "../Assets/party lawn.svg";
import seniorCitizen from "../Assets/senior citizen.svg";
import sprinkler from "../Assets/sprinkler.svg";
import star from "../Assets/star.svg";
import swimming from "../Assets/swimming pool.svg";
import tennis from "../Assets/tennis.svg";
import water from "../Assets/water plant.svg";
import Whatsapp from "../Assets/Whatsapp.svg";
import yoga from "../Assets/yoga.svg";
import "./abode.css";
// import { Carousel } from "3d-react-carousal";
import Carousel from "react-spring-3d-carousel";
import camera from "../Assets/camera.svg";
import clubhouse from "../Assets/club house.svg";
import Frame from "../Assets/Frame.png";
import gym from "../Assets/gym.svg";
import jogging from "../Assets/Jogging.svg";
import left_arr from "../Assets/left_arr.svg";
import left_grey from "../Assets/left_grey.svg";
import logo1440 from "../Assets/logo1440.svg";
import logoo from "../Assets/logoo.svg";
import mainImage from "../Assets/mainImageBig.jpeg";
import mainImage2 from "../Assets/mainImageBig2.png";
import mainImage3 from "../Assets/mainImageBig3.jpeg";
import newLogo from "../Assets/newLogo.png";
import right_arr from "../Assets/right_arr.svg";
import right_grey from "../Assets/right_grey.svg";
import tick from "../Assets/tick.png";
import Vector from "../Assets/Vector.svg";

// import mainImageSmall from "../Assets/mainImage.jpeg";
// import mainImageSmall2 from "../Assets/mainImage2.png";
// import mainImageSmall3 from "../Assets/mainImage3.jpeg";
// import img1 from "../Assets/img1.png";
// import img2 from "../Assets/img2.png";
// import img3 from "../Assets/img3.png";
import changeImage1 from "../Assets/changeImage1.png";
import changeImage2 from "../Assets/changeImage2.png";
import changeImage3 from "../Assets/changeImage3.png";
// import txt from "../Assets/txt.png";
// import img4 from "../Assets/img4.png";
// import img5 from "../Assets/img5.png";
// import img6 from "../Assets/img6.png";
// import img7 from "../Assets/img7.png";
// import img8 from "../Assets/img8.png";
// import img9 from "../Assets/img9.png";
import playgroung from "../Assets/playgroung.svg";
// import img10 from "../Assets/img10.png";
// import img11 from "../Assets/img11.png";
// import img12 from "../Assets/img12.png";
import facebook from "../Assets/facebook.svg";
import insta from "../Assets/insta.svg";
import landingPageImage11240 from "../Assets/landingPageImage1-1240.png";
import landingPageImage1500 from "../Assets/landingPageImage1-500.png";
import landingPageImage1700 from "../Assets/landingPageImage1-700.png";
import landingPageImage1 from "../Assets/landingPageImage1.png";
import landingPageImage21240 from "../Assets/landingPageImage2-1240.png";
import landingPageImage2500 from "../Assets/landingPageImage2-500.png";
import landingPageImage2700 from "../Assets/landingPageImage2-700.png";
import landingPageImage2 from "../Assets/landingPageImage2.png";
import landingPageImage31240 from "../Assets/landingPageImage3-1240.png";
import landingPageImage3500 from "../Assets/landingPageImage3-500.png";
import landingPageImage3700 from "../Assets/landingPageImage3-700.png";
import landingPageImage3 from "../Assets/landingPageImage3.png";
// import floor1 from "../Assets/floor1.svg";
import bhk1 from "../Assets/bhk1.jpg";
import bhk10 from "../Assets/bhk10.jpg";
import bhk2 from "../Assets/bhk2.jpg";
import bhk3 from "../Assets/bhk3.jpg";
import bhk31 from "../Assets/bhk31.jpg";
import bhk32 from "../Assets/bhk32.jpg";
import bhk33 from "../Assets/bhk33.jpg";
import bhk34 from "../Assets/bhk34.jpg";
import bhk35 from "../Assets/bhk35.jpg";
import bhk36 from "../Assets/bhk36.jpg";
import bhk37 from "../Assets/bhk37.jpg";
import bhk38 from "../Assets/bhk38.jpg";
import bhk39 from "../Assets/bhk39.jpg";
import bhk4 from "../Assets/bhk4.jpg";
import bhk5 from "../Assets/bhk5.jpg";
import bhk6 from "../Assets/bhk6.jpg";
import bhk7 from "../Assets/bhk7.jpg";
import bhk8 from "../Assets/bhk8.jpg";
import bhk9 from "../Assets/bhk9.jpg";
import newFloor1 from "../Assets/newFloor1.png";
import newFloor2 from "../Assets/newFloor2.png";
// import floor2 from "../Assets/floor2.svg";
// import floor3 from "../Assets/floor3.svg";
// import floor4 from "../Assets/floor4.svg";
// import floor5 from "../Assets/floor4.svg";
// import floor6 from "../Assets/floor4.svg";
// import floor7 from "../Assets/floor4.svg";
// import floor8 from "../Assets/floor4.svg";
import { Modal } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";


// fire base
import db from '../firebase';
import { collection, addDoc } from "firebase/firestore";

const AbodeMainLandingPage = () => {

  const [activeTab, setActiveTab] = useState("NearBy");
  // const [classS, setClassS] = useState(["preactive", "active", "proactive"]);
  const [bhkk, setBhkk] = useState();
  const [homeImageFlag, setHomeImageFlag] = useState(true);

  const imageUris = [mainImage, mainImage2, mainImage3];
  const [bhkUris, setBhkUris] = useState([
    newFloor1,
    newFloor2,
    bhk2,
    bhk1,
    bhk3,
    bhk4,
    bhk5,
    bhk6,
    bhk7,
    bhk8,
    bhk9,
    bhk10,
    bhk3,
  ]);
  const [bhkTexts, setBhkTexts] = useState([
    "1176 sq.ft.",
    "1207 sq.ft.",
    "1225 sq.ft.",
    "1234 sq.ft.",
    "1235 sq.ft.",
    "1239 sq.ft.",
    "1244 sq.ft.",
    "1255 sq.ft",
    "1256 sq.ft.",
    "1267 sq.ft.",
    "1268 sq.ft.",
    "1273 sq.ft.",
    "1279 sq.ft.",
    "1283 sq.ft.",
  ]);

  const slides = [
    {
      key: 1,
      content: <img src={mainImage} alt="1" />,
    },
    {
      key: 2,
      content: <img src={mainImage2} alt="2" />,
    },
    {
      key: 3,
      content: <img src={mainImage3} alt="5" />,
    },
  ];

  const getChildern = () => {
    const children = document.getElementsByClassName("css-1qzevvg");
    children[0].childNodes[0].src = left_arr;
    children[0].childNodes[0].id = "blahblah";
    children[0].childNodes[1].src = right_arr;
    children[0].childNodes[1].id = "blahblah1";
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [showModall, setShowModal] = useState(false);
  const [showModall2, setShowModal2] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
    setEmail("");
    setPhone("");
    setName("");
  };
  const handleCloseModal2 = () => setShowModal2(false);

  useEffect(() => {
    getChildern();
    // setTimeout(() => {
    //   if (index === 3) {
    //     setIndex(1);
    //   } else setIndex(index + 1);
    // }, 1000);
  }, []);

  const [specification, setSpecification] = useState("livingRoom");

  const [count, setCount] = useState(0);

  // const handleNext = () => {
  //   setCount(count + 1);
  // };

  // const handlePrev = () => {
  //   setCount(count - 1);
  // };
  const [index, setIndex] = useState(0);

  // const handleDownload = async () => {
  //     if (name && phone && email) {
  //         try {
  //             const url = 'http://localhost:3000/brochure.pdf'
  //             fetch(url).then(response => response.blob()).then(blob => {
  //                 const file = window.URL.createObjectURL(new Blob([blob]))
  //                 const filename = url.split("brochure.pdf")

  //             })
  //         } catch (error) {
  //             console.error('Error downloading PDF:', error);
  //             alert('An error occurred while downloading the PDF.');
  //         }
  //     } else {
  //         alert('Please fill all fields before downloading.');
  //     }
  // };



const [overlayFlag, setOverlayFlag] = useState(false);

  const handleDownload = async () => {
    if (email && isValidEmail) {
      // Include isValidEmail in the condition
      try {
        // const response = await fetch("https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Sterling%20Abode%20Brochure.pdf");
  


// need to send email from here
setOverlayFlag(true);
const addTodo = async () => {
   
  try {
      const docRef = await addDoc(collection(db, "mail"), {
        // to: ['abodesterling@gmail.com'], 
        to: ['thesterlingabode@gmail.com'], 
        message: {
          text: 'Enquiry For Sterling Abode',
          subject: `'Sterling Abode Enquiry From ${name} through Tradais'`,
          html: `'<p style="font-family: Arial, sans-serif; font-size: 16px; line-height: 1.5; margin-bottom: 1em;">         Alert  Srivatsa Homes,<br />  <br />       You got a new enquiry for sterling abode web page: <br/> <br /> Hi my Name is <b>${name} </b> my mobile no and email id are as follows <b> ${phone} , ${email}. </b> <br /> get in contact with me and Please take necessary action.      <br />  <br />         Regards,<br />          Tradais Digital        </p>'`
        }   
      });
      // download broucher
      const response = await fetch(`${window.location.href}/brochure.pdf`);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "brochure.pdf");
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      // end of download brouchwr
      console.log("Document written with ID: ", docRef.id);
      setShowModal(false);
      setShowModal2(true);
      setEmail("");
      setPhone("");
      setName("");

setOverlayFlag(false);
      
    } catch (e) {
      console.error("Error adding document: ", e);
      setShowModal(false);
      setShowModal2(true);
      setEmail("");
      setPhone("");
      setName("");
      setOverlayFlag(false);
    }
}

addTodo();
      
      } catch (error) {
        console.error("Error downloading PDF:", error);
        alert("An error occurred while downloading the PDF.");
      }
    } else {
      alert(
        "Please fill all fields and provide a valid email address before downloading."
      );
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex(index + 1);
    }, 4000);

    return () => clearTimeout(timer);
  }, [index]);

  const overviewRef = useRef(null);
  const amenitiesRef = useRef(null);
  const galleryRef = useRef(null);
  const floorPlanRef = useRef(null);
  const locationRef = useRef(null);
  const contactRef = useRef(null);
  const scrollToOverview = () => {
    overviewRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToAmenities = () => {
    amenitiesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToGallery = () => {
    galleryRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToFloorPlan = () => {
    floorPlanRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToLocation = () => {
    locationRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (homeImageFlag) {
        setCount((prevCount) => (prevCount + 1) % 3);
      } else {
        setHomeImageFlag(true);
      }
    }, 4000);

    return () => clearInterval(interval);
  });

  // for gallery slidesssssssssssssssssss
  const [enlarge, setEnlarge] = useState(false);
  const [enlargedSlideIndex, setEnlargedSlideIndex] = useState(0);
  const enlragedSlideRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [enlargedTop, setEnlargedTop] = useState(0);

  // for bhkkk
  const [enlargeBHK, setEnlargeBHK] = useState(false);
  const [enlargedBHKIndex, setEnlargedBHKIndex] = useState(0);
  const bhkRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [bhkTop, setBhkTop] = useState(0);


  const handleClickOutside = (event) => {
    if (
      enlragedSlideRef.current &&
      !enlragedSlideRef.current.contains(event.target)
    ) {
      console.log("clicked");
      //   setShowPopup(false);
      setEnlarge(false);
      setEnlargedSlideIndex(0);
      if (document.getElementById("body")) {
        document.getElementById("body").style.overflowY = "scroll";
      }
      if (document.getElementById("lalala")) {
        document.getElementById("lalala").style.filter = "";
        document.getElementById("lalala").style.transform = "";
      }
    }

    if (bhkRef.current && !bhkRef.current.contains(event.target)) {
      //   setShowPopup(false);
      setEnlargeBHK(false);
      setEnlargedBHKIndex(0);
      if (document.getElementById("body")) {
        document.getElementById("body").style.overflowY = "scroll";
      }
      if (document.getElementById("lalala")) {
        document.getElementById("lalala").style.filter = "";
        document.getElementById("lalala").style.transform = "";
      }
    }
  };
  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;

    // Remove any non-numeric characters from the input
    const numericValue = inputValue.replace(/\D/g, "");

    // Update the phone state with the numeric value
    setPhone(numericValue);
  };
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(enteredEmail));
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>

    {overlayFlag && (<div 
          className="fixed w-full h-full bg-[rgb(16 27 24)] z-40"></div>)}
      {enlarge && (
        <div
          className="fixed w-full h-full bg-[rgb(16 27 24)] z-30"
          // style={{ top: enlargedTop + window.innerHeight / 95 }}
        >
          <div className="flex justify-center h-full">
            <div
              className="flex flex-col justify-center "
              ref={enlragedSlideRef}
            >
              <img
                className="w-[64vw] h-[74vh] object-cover"
                src={imageUris[enlargedSlideIndex]}
                alt=""
              />
              <div className="py-[1.2rem] px-[2.5rem] flex justify-between bg-[#101b18] max-w-[64vw]">
                <span className="flex justify-center text-white">
                  {enlargedSlideIndex + 1} / {imageUris.length}
                </span>
                <div className="cursor-pointer flex justify-center ">
                  <img
                    alt="."
                    src={left_arr}
                    style={{ marginRight: "1rem", width: "2.3rem" }}
                    onClick={() => {
                      if (enlargedSlideIndex > 0) {
                        setEnlargedSlideIndex(enlargedSlideIndex - 1);
                      }
                    }}
                  />
                  <img
                    alt="."
                    src={right_arr}
                    style={{ width: "2.3rem" }}
                    onClick={() => {
                      if (enlargedSlideIndex < imageUris.length - 1) {
                        setEnlargedSlideIndex(enlargedSlideIndex + 1);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {enlargeBHK && (
        <div
          className="fixed w-full h-full bg-[rgb(16 27 24)] z-30"
          // style={{ top: enlargedTop + window.innerHeight / 95 }}
        >
          <div className="flex justify-center h-full">
            <div className="flex flex-col justify-center" ref={bhkRef}>
              {/* <div
          className="enlargedSlide"
          ref={bhkRef}
          style={{ top: bhkTop + 61 }}
        > */}
              <img
                src={bhkUris[enlargedBHKIndex]}
                className="w-[64vw] h-[74vh] object-cover"
                alt=""
              />
              <div className="py-[1.2rem] px-[2.5rem] flex justify-between bg-[#101b18] max-w-[64vw]">
                <span className="text-white">
                  {enlargedBHKIndex + 1} / {bhkUris.length}
                </span>
                <span className="text-white">{bhkTexts[enlargedBHKIndex]}</span>
                <div className="cursor-pointer flex justify-center ">
                  <img
                    alt="logo"
                    src={left_arr}
                    style={{ marginRight: "1rem", width: "2.3rem" }}
                    onClick={() => {
                      if (enlargedBHKIndex > 0) {
                        setEnlargedBHKIndex(enlargedBHKIndex - 1);
                      }
                    }}
                  />
                  <img
                    alt="logo"
                    src={right_arr}
                    style={{ width: "2.3rem" }}
                    onClick={() => {
                      if (enlargedBHKIndex < bhkUris.length - 1) {
                        setEnlargedBHKIndex(enlargedBHKIndex + 1);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div id="lalala">
        {/* first part */}
        <div>
          <div className="bg-[#122620] h-[100vh] relative flex flex-col">
            {/* navbar */}
            <div
              className="absolute md:hidden bottom-[16px] right-[16px]"
              onClick={() => {
                window.open(
                  "https://web.whatsapp.com/send?phone=+919100008979"
                );
              }}
            >
              <img alt="logo" src={Whatsapp} />
            </div>
            <div className="h-[68px] ls:h-auto ls:absolute w-full pr-[20px] md:px-[40px] lm:px-[60px] bg-[transparent] flex justify-between ">
              <div className="z-30">
                <img src={logoo} alt="logo" className="hidden lm:block" />
                <img
                  alt="logo"
                  src={logo1440}
                  className="hidden md:block lm:hidden"
                />
                <div className="md:hidden absolute w-full flex justify-center">
                  <img
                    alt="logo"
                    src={logo1440}
                    className="md:hidden logo-highlight"
                  />
                </div>
              </div>
              <div className="hidden lm:flex z-30 items-center gap-[24px] pb-[15px] text-[16px] font-medium text-white">
                <span className=" cursor-pointer" onClick={scrollToOverview}>
                  OVERVIEW
                </span>
                <span className="cursor-pointer" onClick={scrollToAmenities}>
                  AMENITIES
                </span>
                <span className="cursor-pointer" onClick={scrollToGallery}>
                  GALLERY
                </span>
                <span className="cursor-pointer" onClick={scrollToFloorPlan}>
                  FLOOR PLAN
                </span>
                <span className="cursor-pointer" onClick={scrollToLocation}>
                  LOCATION
                </span>
                <span className="cursor-pointer" onClick={scrollToContact}>
                  CONTACT
                </span>
              </div>
              <div className="z-30 flex md:pb-[15px]">
                <div className="items-center gap-[4px] hidden md:flex ">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="20"
                      height="20"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="#fff"
                        d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
                      ></path>
                      <path
                        fill="#fff"
                        d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
                      ></path>
                      <path
                        fill="#cfd8dc"
                        d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
                      ></path>
                      <path
                        fill="#40c351"
                        d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                      ></path>
                      <path
                        fill="#fff"
                        fillRule="evenodd"
                        d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <span
                    className="text-[15px] md:text-[16px] font-medium text-white cursor-pointer pr-[24px] lm:pr-[5px]"
                    onClick={() => {
                      window.open(
                        "https://web.whatsapp.com/send?phone=+919100008979"
                      );
                    }}
                  >
                    +91 9100008979
                  </span>
                </div>
                <div
                  className="lm:hidden flex flex-col justify-center cursor-pointer"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  <img alt="logo" src={Vector} />
                </div>
              </div>
            </div>
            <div
              ref={overviewRef}
              className="flex flex-col-reverse justify-end ls:flex-row pl-[20px] md:pl-[60px] ls:pl-[0px] text-[#d6ad60] flex-grow"
            >
              {/* gap-[20px] md:gap-[24px]    pt-[40px]  md:pt-[44px] */}
              <div className="flex flex-col flex-grow justify-around ls:justify-start w-full ls:gap-[32px] lg:gap-[40px] xl:gap-[52px] ls:pt-[145px] lg:pt-[160px] xl:pt-[215px]  ls:w-[40%] ls:pl-[80px] xl:pl-[120px]">
                <div className="hidden lg:block border-solid border-[2px] border-[#d6ad60] lg:p-[16px] xl:p-[20px] text-center w-fit">
                  <div
                    className="lg:text-[36px] xl:text-[40px] font-normal"
                    style={{ fontFamily: "Cormorant Garamond" }}
                  >
                    STERLING
                  </div>
                  <div
                    className="lg:text-[36px] xl:text-[40px] font-normal"
                    style={{ fontFamily: "Cormorant Garamond" }}
                  >
                    ABODE
                  </div>
                  <div
                    className="pt-[5px] lg:text-[22px] xl:text-[24px]"
                    style={{ fontFamily: "Cormorant Garamond" }}
                  >
                    Redifining luxury
                  </div>
                </div>
                <div
                  className="text-[24px] ls:text-[28px] lg:text-[32px] xl:text-[36px] font-medium ls:max-w-[385px]"
                  style={{ fontFamily: "Lora" }}
                >
                  Your very own space where everything feels divine
                </div>
                <div
                  className="text-[16px] md:etxt-[20px] ls:text-[18px] lg:text-[20px] xl:text-[24px] font-medium ls:max-w-[385px]"
                  style={{ fontFamily: "Lora" }}
                >
                  Get Premium 2 & 3 BHK Flats for sale in Hyderabad
                </div>
                {/* <div
                  className="text-[16px] ls:text-[20px] lg:text-[24px] xl:text-[28px] font-medium"
                  style={{ fontFamily: "Lora" }}
                >
                  Ready to Move-In
                </div> */}
                <div className="flex justify-between">
                  <div
                    className="download_broch"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    Download Brochure
                  </div>
                </div>
              </div>
              <div className="h-[336px] md:h-[500px] ls:h-full w-full ls:w-[60%] relative">
                <div className="image-slider h-full hidden lm:flex">
                  {count % 3 === 0 && (
                    <img
                      alt="logo"
                      src={landingPageImage1}
                      className="h-full w-full"
                    />
                  )}
                  {count % 3 === 1 && (
                    <img
                      alt="logo"
                      src={landingPageImage2}
                      className="h-full w-full"
                    />
                  )}
                  {count % 3 === 2 && (
                    <img
                      alt="logo"
                      src={landingPageImage3}
                      className="h-full w-full"
                    />
                  )}
                </div>
                <div className="image-slider h-full hidden ls:flex lm:hidden">
                  {count % 3 === 0 && (
                    <img
                      alt="logo"
                      src={landingPageImage11240}
                      className="h-full w-full"
                    />
                  )}
                  {count % 3 === 1 && (
                    <img
                      alt="logo"
                      src={landingPageImage21240}
                      className="h-full w-full"
                    />
                  )}
                  {count % 3 === 2 && (
                    <img
                      alt="logo"
                      src={landingPageImage31240}
                      className="h-full w-full"
                    />
                  )}
                </div>
                <div className="image-slider h-full hidden sm:block ls:hidden">
                  {count % 3 === 0 && (
                    <img
                      alt="logo"
                      src={landingPageImage1700}
                      className="h-full w-full"
                    />
                  )}
                  {count % 3 === 1 && (
                    <img
                      alt="logo"
                      src={landingPageImage2700}
                      className="h-full w-full"
                    />
                  )}
                  {count % 3 === 2 && (
                    <img
                      alt="logo"
                      src={landingPageImage3700}
                      className="h-full w-full"
                    />
                  )}
                </div>
                <div className="image-slider h-full sm:hidden">
                  {count % 3 === 0 && (
                    <img
                      alt="logo"
                      src={landingPageImage1500}
                      className="h-full w-full"
                    />
                  )}
                  {count % 3 === 1 && (
                    <img
                      alt="logo"
                      src={landingPageImage2500}
                      className="h-full w-full"
                    />
                  )}
                  {count % 3 === 2 && (
                    <img
                      alt="logo"
                      src={landingPageImage3500}
                      className="h-full w-full"
                    />
                  )}
                </div>
                <div className="text-[14px] md:text-[16px] ls:text-[20px] lg:text-[24px] font-medium text-[#d6ad60] ">
                  {count % 3 === 0 && (
                    <span
                      className="absolute bg-[#12262099] border-solid border-[1px] border-[#d6ad60] p-[10px] rounded-[8px] right-[20px] md:right-[50px] ls:right-[80px] lg:right-[150px] bottom-[67px] md:bottom-[117px] ls:bottom-[157px] lg:bottom-[185px] xl:bottom-[255px]"
                      style={{ fontFamily: "Lora" }}
                    >
                      12600 sq.ft. Club House
                    </span>
                  )}
                  {count % 3 === 1 && (
                    <span
                      className="absolute bg-[#12262099] border-solid border-[1px] border-[#d6ad60] p-[10px] rounded-[8px] right-[20px] md:right-[50px] ls:right-[80px] lg:right-[150px] bottom-[67px] md:bottom-[117px] ls:bottom-[157px] lg:bottom-[185px] xl:bottom-[255px]"
                      style={{ fontFamily: "Lora" }}
                    >
                      World Class Amenities
                    </span>
                  )}
                  {count % 3 === 2 && (
                    <span
                      className="absolute bg-[#12262099] border-solid border-[1px] border-[#d6ad60] p-[10px] rounded-[8px] right-[20px] md:right-[50px] ls:right-[80px] lg:right-[150px] bottom-[67px] md:bottom-[117px] ls:bottom-[157px] lg:bottom-[185px] xl:bottom-[255px]"
                      style={{ fontFamily: "Lora" }}
                    >
                      Modern 2&3 BHK Apartments
                    </span>
                  )}
                  <div className="flex absolute left-[12px] md:left-[144px] ls:left-[191px] lm:left-[227px] lg:left-[372px] xl:left-[525px]  bottom-[16px] md:bottom-[24px] ls:bottom-[40px] lm:bottom-[50px] xl:bottom-[90px]">
                    <svg
                      className="ls:hidden cursor-pointer"
                      style={{ marginRight: "1rem" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      onClick={() => {
                        setCount((prevCount) => {
                          if (prevCount === 0) return 2;
                          return (prevCount - 1) % 3;
                        });
                        setHomeImageFlag(false);
                      }}
                    >
                      <rect width="32" height="32" rx="16" fill="#D6AD60" />
                      <path
                        d="M21.5996 15.2H12.1116L15.0156 11.712C15.1514 11.5487 15.2167 11.3381 15.1972 11.1265C15.1777 10.915 15.075 10.7198 14.9116 10.584C14.7482 10.4483 14.5376 10.3829 14.3261 10.4024C14.1145 10.4219 13.9194 10.5247 13.7836 10.688L9.78361 15.488C9.7567 15.5262 9.73263 15.5663 9.71161 15.608C9.71161 15.648 9.71161 15.672 9.65561 15.712C9.61935 15.8038 9.60036 15.9014 9.59961 16C9.60036 16.0987 9.61935 16.1963 9.65561 16.288C9.65561 16.328 9.65561 16.352 9.71161 16.392C9.73263 16.4338 9.7567 16.4739 9.78361 16.5121L13.7836 21.312C13.8588 21.4024 13.953 21.475 14.0595 21.5248C14.166 21.5745 14.2821 21.6002 14.3996 21.6C14.5865 21.6004 14.7677 21.5353 14.9116 21.416C14.9926 21.3489 15.0596 21.2664 15.1087 21.1733C15.1577 21.0803 15.188 20.9784 15.1976 20.8736C15.2073 20.7688 15.1962 20.6632 15.165 20.5627C15.1337 20.4622 15.083 20.3689 15.0156 20.2881L12.1116 16.8H21.5996C21.8118 16.8 22.0153 16.7158 22.1653 16.5657C22.3153 16.4157 22.3996 16.2122 22.3996 16C22.3996 15.7879 22.3153 15.5844 22.1653 15.4344C22.0153 15.2843 21.8118 15.2 21.5996 15.2Z"
                        fill="#122620"
                      />
                    </svg>
                    <svg
                      className="ls:hidden cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      onClick={() => {
                        setCount((prevCount) => (prevCount + 1) % 3);
                        setHomeImageFlag(false);
                      }}
                    >
                      <rect width="32" height="32" rx="16" fill="#D6AD60" />
                      <path
                        d="M10.3996 16.8H19.8876L16.9836 20.288C16.9164 20.3689 16.8657 20.4623 16.8346 20.5627C16.8034 20.6632 16.7923 20.7688 16.802 20.8736C16.8215 21.0851 16.9242 21.2802 17.0876 21.416C17.251 21.5518 17.4616 21.6171 17.6731 21.5976C17.8847 21.5781 18.0798 21.4754 18.2156 21.312L22.2156 16.512C22.2425 16.4738 22.2666 16.4337 22.2876 16.392C22.2876 16.352 22.3276 16.328 22.3436 16.288C22.3799 16.1963 22.3989 16.0987 22.3996 16C22.3989 15.9014 22.3799 15.8038 22.3436 15.712C22.3436 15.672 22.3036 15.648 22.2876 15.608C22.2666 15.5663 22.2425 15.5262 22.2156 15.488L18.2156 10.688C18.1404 10.5977 18.0462 10.5251 17.9397 10.4753C17.8333 10.4255 17.7171 10.3998 17.5996 10.4C17.4127 10.3997 17.2315 10.4648 17.0876 10.584C17.0066 10.6512 16.9396 10.7337 16.8906 10.8267C16.8415 10.9198 16.8112 11.0217 16.8016 11.1264C16.7919 11.2312 16.803 11.3369 16.8343 11.4374C16.8655 11.5379 16.9162 11.6312 16.9836 11.712L19.8876 15.2H10.3996C10.1874 15.2 9.98395 15.2843 9.83392 15.4343C9.6839 15.5844 9.59961 15.7879 9.59961 16C9.59961 16.2122 9.6839 16.4157 9.83392 16.5657C9.98395 16.7157 10.1874 16.8 10.3996 16.8Z"
                        fill="#122620"
                      />
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      style={{ marginRight: "1rem" }}
                      className="ls:block hidden cursor-pointer"
                      onClick={() => {
                        setCount((prevCount) => {
                          if (prevCount === 0) return 2;
                          return (prevCount - 1) % 3;
                        });
                        setHomeImageFlag(false);
                      }}
                    >
                      <rect width="40" height="40" rx="20" fill="#D6AD60" />
                      <path
                        d="M27 19H15.14L18.77 14.64C18.9397 14.4358 19.0214 14.1725 18.997 13.908C18.9726 13.6436 18.8442 13.3997 18.64 13.23C18.4358 13.0602 18.1725 12.9786 17.9081 13.0029C17.6437 13.0273 17.3997 13.1558 17.23 13.36L12.23 19.36C12.1964 19.4077 12.1663 19.4578 12.14 19.51C12.14 19.56 12.14 19.59 12.07 19.64C12.0247 19.7546 12.0009 19.8767 12 20C12.0009 20.1233 12.0247 20.2453 12.07 20.36C12.07 20.41 12.07 20.44 12.14 20.49C12.1663 20.5421 12.1964 20.5922 12.23 20.64L17.23 26.64C17.324 26.7529 17.4418 26.8436 17.5748 26.9059C17.7079 26.9681 17.8531 27.0002 18 27C18.2337 27.0004 18.4601 26.9191 18.64 26.77C18.7413 26.686 18.825 26.5829 18.8863 26.4666C18.9477 26.3502 18.9855 26.2229 18.9975 26.0919C19.0096 25.961 18.9957 25.8289 18.9567 25.7033C18.9176 25.5777 18.8542 25.461 18.77 25.36L15.14 21H27C27.2652 21 27.5196 20.8946 27.7071 20.7071C27.8946 20.5195 28 20.2652 28 20C28 19.7348 27.8946 19.4804 27.7071 19.2929C27.5196 19.1053 27.2652 19 27 19Z"
                        fill="#122620"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      className="ls:block hidden cursor-pointer"
                      onClick={() => {
                        setCount((prevCount) => (prevCount + 1) % 3);
                        setHomeImageFlag(false);
                      }}
                    >
                      <rect width="40" height="40" rx="20" fill="#D6AD60" />
                      <path
                        d="M13 21H24.86L21.23 25.36C21.146 25.4611 21.0826 25.5778 21.0437 25.7034C21.0047 25.829 20.9909 25.961 21.003 26.0919C21.0274 26.3564 21.1558 26.6003 21.36 26.77C21.5642 26.9397 21.8275 27.0214 22.0919 26.997C22.3563 26.9726 22.6003 26.8442 22.77 26.64L27.77 20.64C27.8036 20.5923 27.8337 20.5421 27.86 20.49C27.86 20.44 27.91 20.41 27.93 20.36C27.9753 20.2453 27.9991 20.1233 28 20C27.9991 19.8767 27.9753 19.7547 27.93 19.64C27.93 19.59 27.88 19.56 27.86 19.51C27.8337 19.4579 27.8036 19.4077 27.77 19.36L22.77 13.36C22.676 13.2471 22.5582 13.1563 22.4252 13.0941C22.2921 13.0319 22.1469 12.9998 22 13C21.7663 12.9995 21.5399 13.0809 21.36 13.23C21.2587 13.314 21.175 13.4171 21.1137 13.5334C21.0523 13.6497 21.0145 13.7771 21.0025 13.908C20.9904 14.039 21.0043 14.1711 21.0433 14.2967C21.0824 14.4223 21.1458 14.539 21.23 14.64L24.86 19H13C12.7348 19 12.4804 19.1054 12.2929 19.2929C12.1054 19.4804 12 19.7348 12 20C12 20.2652 12.1054 20.5196 12.2929 20.7071C12.4804 20.8946 12.7348 21 13 21Z"
                        fill="#122620"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 2nd part */}
        <div className="background-div bg-no-repeat bg-cover bg-center opacity-[85%] items-center flex  flex-col justify-center gap-[90px] h-[546px]">
          <div className="frst_box">
            <div className="overview">Overview</div>
            <div className="yellow_head" style={{ fontFamily: "lora" }}>
              Building Dreams with Sterling Abode
            </div>
            <div className="meth_head">
              Explore a world of spacious living and contemporary design with
              our meticulously crafted 2 - 3 BHK premium Flats & apartments in
              Hyderabad. Experience the security and serenity of gated community
              apartments at Sterling Abode.
            </div>
            {/* <div className='learn'>learn more</div> */}
          </div>
          <div className="secondRow">
            <div className="secondRow2">
              <div className="second_box">
                <div className="firstttt">12k+</div>
                <div className="scnd">Sq. ft. Club House</div>
              </div>
              <div className="second_box">
                <div className="firstttt">30+</div>
                <div className="scnd">Amenities</div>
              </div>
            </div>
            <div className="secondRow2">
              <div className="second_box">
                <div className="firstttt">
                  5<span style={{ fontSize: "50px" }}>Lakh+</span>
                </div>
                <div className="scnd">Sq. ft. Area</div>
              </div>
              <div className="second_box">
                <div className="firstttt">220</div>
                <div className="scnd">Units</div>
              </div>
            </div>
          </div>
        </div>
        {/* 3rd part */}
        <div
          ref={amenitiesRef}
          className="bg-[#122620] h-fit flex flex-col justify-around items-center px-[20px] md:px-[60px] lm:px-[100px] lg:px-[120px] xl:px-[150px] py-[40px] md:py-[50px]"
        >
          <div className="text-[18px] text-white">Amenities</div>
          <div className="Modern_spaced">Modern Spaces Tailored for You</div>
          <div className="coke_head">
            Our apartments offer not just a home but a heaven where your peace
            of mind is a top priority.
          </div>
          <div className="mb-[50px] w-full flex justify-center">
            <div className="grid-container w-[100%]">
              <div className="grid-item grid_1">
                <div className="flex justify-center">
                  <img className="w-[75px]" src={jogging} alt="logo" />
                </div>
                <div className="text-[18px] text-white pt-[20px]">
                  Jogging Track
                </div>
              </div>
              <div className="grid-item grid_2">
                <div className="flex justify-center">
                  <img className="w-[75px]" src={swimming} alt="logo" />
                </div>
                <div className="text-[18px] text-white pt-[20px]">
                  Swimming Pool
                </div>
              </div>
              <div className="grid-item grid_3">
                <div className="flex justify-center">
                  <img className="w-[75px]" src={camera} alt="logo" />
                </div>
                <div className="text-[18px] text-white pt-[20px]">
                  24x7 Security
                </div>
              </div>
              <div className="grid-item grid_4">
                <div className="flex justify-center">
                  <img className="w-[75px]" src={gym} alt="logo" />
                </div>
                <div className="text-[18px] text-white pt-[20px]">
                  Gymnasium
                </div>
              </div>
              <div className="grid-item grid_5">
                <div className="flex justify-center">
                  <img className="w-[75px]" src={water} alt="logo" />
                </div>
                <div className="text-[18px] text-white pt-[20px]">
                  Water Softener Plant{" "}
                </div>
              </div>
              <div className="grid-item grid_6">
                <div className="flex justify-center">
                  <img className="w-[75px]" src={yoga} alt="logo" />
                </div>
                <div className="text-[18px] text-white pt-[20px]">
                  Yoga / Meditation Area
                </div>
              </div>
              <div className="grid-item grid_7">
                <div className="flex justify-center">
                  <img className="w-[75px]" src={seniorCitizen} alt="logo" />
                </div>
                <div className="text-[18px] text-white pt-[20px]">
                  Senior Citizen Site Out
                </div>
              </div>
              <div className="grid-item grid_8">
                <div className="flex justify-center">
                  <img className="w-[75px]" src={tennis} alt="logo" />
                </div>
                <div className="text-[18px] text-white pt-[20px]">
                  Lawn Tennis Court
                </div>
              </div>
              <div className="grid-item grid_9">
                <div className="flex justify-center">
                  <img className="w-[75px]" src={partylawn} alt="logo" />
                </div>
                <div className="text-[18px] text-white pt-[20px]">
                  Party Lawn
                </div>
              </div>
              <div className="grid-item grid_10">
                <div className="flex justify-center">
                  <img className="w-[75px]" src={clubhouse} alt="logo" />
                </div>
                <div className="text-[18px] text-white pt-[20px]">
                  Club House
                </div>
              </div>
              <div className="grid-item grid_11">
                <div className="flex justify-center">
                  <img className="w-[75px]" src={playgroung} alt="logo" />
                </div>
                <div className="text-[18px] text-white pt-[20px]">
                  Children’s Play Area
                </div>
              </div>
              <div className="grid-item grid_12">
                <div className="flex justify-center">
                  <img className="w-[75px]" src={sprinkler} alt="logo" />
                </div>
                <div className="text-[18px] text-white pt-[20px]">
                  Fire Fighting System
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-[18px] text-white pt-[20px]"
            style={{ marginBottom: "13px" }}
          >
            and so much more...
          </div>
          <div
            className="download_broch"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Download Brochure
          </div>
        </div>
        {/* 4th part */}
        <div className="fourth_part">
          <div className="comn_hox22">
            <div className="yellow_head2" style={{ fontFamily: "lora" }}>
              Specifications
            </div>
          </div>
          {/* bydefault */}
          {specification === "livingRoom" && (
            <div className="overALL_Img">
              <div>
                <img className="immm1" src={changeImage1} alt="logo" />
              </div>
              <div>
                <div className="flex flex-col gap-[16px]">
                  <div className="third_frst">Living/Dining Room</div>
                  <div className="gapppppguys">
                    <span>
                      <img
                        style={{ height: "20px", width: "20px" }}
                        alt=""
                        src={star}
                      />
                    </span>
                    <span className="walls">
                      Floor - Double charged vitrified tiles
                    </span>
                  </div>
                  <div className="gapppppguys">
                    <span>
                      <img
                        style={{ height: "20px", width: "20px" }}
                        alt=""
                        src={star}
                      />
                    </span>
                    <span className="walls">Walls - Acrylic Emulsion</span>
                  </div>
                  <div className="gapppppguys">
                    <span>
                      <img
                        style={{ height: "20px", width: "20px" }}
                        alt=""
                        src={star}
                      />
                    </span>
                    <span className="walls">
                      Main Door - Teak wood frame & teak veneered shutter
                    </span>
                  </div>
                </div>
                <div className="gapagain">
                  <div>
                    <img alt="logo" src={left_grey} />
                  </div>
                  <div className="walls">1/3</div>
                  <div
                    onClick={() => {
                      setSpecification("Bedroom");
                    }}
                  >
                    <img alt="logo" src={right_arr} />
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* thenNext */}
          {specification === "Bedroom" && (
            <div className="overALL_Img">
              <div>
                <img alt="logo" className="immm1" src={changeImage2} />
              </div>
              <div>
                <div className="flex flex-col gap-[16px]">
                  <div className="third_frst">Bedroom</div>
                  <div className="gapppppguys">
                    <span>
                      <img
                        style={{ height: "20px", width: "20px" }}
                        alt=""
                        src={star}
                      />
                    </span>
                    <span className="walls">
                      Floor - Double charged vitrified tiles
                    </span>
                  </div>
                  <div className="gapppppguys">
                    <span>
                      <img
                        style={{ height: "20px", width: "20px" }}
                        alt=""
                        src={star}
                      />
                    </span>
                    <span className="walls">Walls - Acrylic Emulsion</span>
                  </div>
                  <div className="gapppppguys">
                    <span>
                      <img
                        style={{ height: "20px", width: "20px" }}
                        alt=""
                        src={star}
                      />
                    </span>
                    <span className="walls">
                      Windows - Glass window system with mosquito mesh
                    </span>
                  </div>
                </div>
                <div className="gapagain2">
                  <div
                    onClick={() => {
                      setSpecification("livingRoom");
                    }}
                  >
                    <img src={left_arr} alt="logo" />
                  </div>
                  <div className="walls" s>
                    2/3
                  </div>
                  <div
                    onClick={() => {
                      setSpecification("Kitchen");
                    }}
                  >
                    <img src={right_arr} alt="logo" />
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* againNext */}
          {specification === "Kitchen" && (
            <div className="overALL_Img">
              <div>
                <img className="immm1" src={changeImage3} alt="logo" />
              </div>
              <div>
                <div className="flex flex-col gap-[16px]">
                  <div className="third_frst">Kitchen</div>
                  {/* <div className='gapppppguys'>
                                        <span><img style={{ height: "20px", width: "20px" }} alt='' src={star} /></span>
                                        <span className='walls'>Walls- Tiles up-to 2’ above counter & acrylic emulsion paint in balance area</span>
                                    </div> */}
                  <div className="gapppppguys">
                    <span>
                      <img
                        style={{ height: "20px", width: "20px" }}
                        alt=""
                        src={star}
                      />
                    </span>
                    <span className="walls">Walls- Acrylic Emulsion </span>
                  </div>
                  <div className="gapppppguys">
                    <span>
                      <img
                        style={{ height: "20px", width: "20px" }}
                        alt=""
                        src={star}
                      />
                    </span>
                    <span className="walls">
                      Floor - Double charged vitrified tiles
                    </span>
                  </div>
                  {/* <div className='gapppppguys'>
                                        <span><img style={{ height: "20px", width: "20px" }} alt='' src={star} /></span>
                                        <span className='walls'>Counter - Granite /Synthetic Stone</span>
                                    </div> */}
                  <div className="gapppppguys">
                    <span>
                      <img
                        style={{ height: "20px", width: "20px" }}
                        alt=""
                        src={star}
                      />
                    </span>
                    <span className="walls">
                      Fittings - Individual tap for cooing, washing and other
                      purpose. Provision for Dish Washer in utility area.
                    </span>
                  </div>
                </div>
                <div className="gapagain3">
                  <div
                    onClick={() => {
                      setSpecification("Bedroom");
                    }}
                  >
                    <img src={left_arr} alt="logo" />
                  </div>
                  <div className="walls">3/3</div>
                  <div>
                    <img src={right_grey} alt="logo" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* 5th part */}
        <div
          ref={galleryRef}
          style={{
            backgroundColor: "#122620",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="gallery" style={{ fontFamily: "lora" }}>
            Gallery
          </div>
          {/* <div className="h-[500px] w-[25%] m-auto"> */}
          <Carousel
            slides={slides}
            goToSlide={index}
            showNavigation={true}
            enableSwipe={true}
          />
          {/* </div> */}
          <span style={{ textAlign: "-webkit-center" }} className="enlargee">
            <div
              className="learn text-white"
              onClick={() => {
                setEnlarge(true);
                // setEnlargedTop(window.scrollY);
                document.getElementById("body").style.overflow = "hidden";
                document.getElementById("lalala").style.filter =
                  "brightness(0.5) blur(4px) drop-shadow(black 0px 0px 18px)";
                document.getElementById("lalala").style.transform =
                  "scale(1.02)";
              }}
            >
              Click to enlarge
            </div>
            <div
              className="download_broch mt-[32px]"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Download Brochure
            </div>
          </span>
          <div className="newclass"></div>
        </div>
        {/* 6th part */}
        <div
          ref={floorPlanRef}
          className="sixth_part flex flex-col bg-cover bg-center opacity-[85%] bg-no-repeat items-center py-[40px] md:py-[50px] px-[20px] md:px-[60px] lm:ps-[20px] lg:px-[60px] xl:px-[100px] gap-[32px] ls:gap-[40px]"
        >
          <div className="comn_hox">
            <div className="overview" style={{ paddingTop: "5px" }}>
              Floor Plan
            </div>
            <div className="yellow_head" style={{ fontFamily: "lora" }}>
              Discover Your Perfect Space
            </div>
          </div>
          {!bhkk ? (
            <div className="comm_bhk">
              <div className="bbhk">2 BHK</div>
              <div
                className="hbhk"
                onClick={() => {
                  setBhkk(true);
                  setBhkUris([
                    bhk32,
                    bhk31,
                    bhk33,
                    bhk34,
                    bhk35,
                    bhk36,
                    bhk37,
                    bhk38,
                    bhk39,
                  ]);
                  setBhkTexts([
                    "1545 sq.ft.",
                    "1583 sq.ft.",
                    "1586 sq.ft.",
                    "1607 sq.ft.",
                    "1608 sq.ft.",
                    "1675 sq.ft.",
                    "1680 sq.ft",
                    "1694 sq.ft.",
                    "1697 sq.ft.",
                  ]);
                  if (document.getElementById("idgoeshere")) {
                    document.getElementById("idgoeshere").scrollLeft = 0;
                  }
                  if (document.getElementById("idgoesheree")) {
                    document.getElementById("idgoesheree").scrollLeft = 0;
                  }
                }}
              >
                3 BHK
              </div>
            </div>
          ) : (
            <div className="comm_bhk">
              <div
                className="hbhk"
                onClick={() => {
                  setBhkk(false);
                  setBhkUris([
                    newFloor1,
                    newFloor2,
                    bhk2,
                    bhk1,
                    bhk3,
                    bhk4,
                    bhk5,
                    bhk6,
                    bhk7,
                    bhk8,
                    bhk9,
                    bhk10,
                    bhk3,
                  ]);
                  setBhkTexts([
                    "1176 sq.ft.",
                    "1207 sq.ft.",
                    "1225 sq.ft.",
                    "1234 sq.ft.",
                    "1235 sq.ft.",
                    "1239 sq.ft.",
                    "1244 sq.ft.",
                    "1255 sq.ft",
                    "1256 sq.ft.",
                    "1267 sq.ft.",
                    "1268 sq.ft.",
                    "1273 sq.ft.",
                    "1279 sq.ft.",
                    "1283 sq.ft.",
                  ]);
                  if (document.getElementById("idgoeshere")) {
                    document.getElementById("idgoeshere").scrollLeft = 0;
                  }
                  if (document.getElementById("idgoesheree")) {
                    document.getElementById("idgoesheree").scrollLeft = 0;
                  }
                }}
              >
                2 BHK
              </div>
              <div className="bbhk">3 BHK</div>
            </div>
          )}
          <div className="holeefuk">
            {!bhkk ? (
              <div className="comn_floor" id="idgoeshere">
                {/* mediaaquery */}
                {/* <div className='top_two'>
                                    <div className='frst_floor'>
                                        <div className='frst_frst'>
                                            <div><img className='floor_imagesss' src={floor1} /></div>
                                            <div><img className='floor_imagesss' src={floor2} /></div>
                                        </div>
                                        <div className='numberBold2'>1176 sq.ft.</div>
                                    </div>
                                    <div className='scnd_floor'>
                                        <div className='frst_frst'>
                                            <div><img className='floor_imagesss' src={floor3} /></div>
                                            <div><img className='floor_imagesss' src={floor4} /></div>
                                        </div>
                                        <div className='numberBold2'>1226 sq.ft.</div>

                                    </div>
                                </div>
                                <div className='third_floor'>
                                    <div className='frst_frst'>
                                        <div><img className='floor_imagesss' src={floor3} /></div>
                                        <div><img className='floor_imagesss' src={floor4} /></div>
                                    </div>
                                    <div className='numberBold2'>1176 sq.ft.</div>
                                </div> */}
                <img
                  src={left_arr}
                  className="leftArrow"
                  onClick={() => {
                    if (document.getElementById("idgoeshere")) {
                      document.getElementById("idgoeshere").scrollLeft =
                        document.getElementById("idgoeshere").scrollLeft - 450;
                    }
                  }}
                  alt="logo"
                />

                <div className="curr_image">
                  <img src={newFloor1} alt="" />
                  <span>1176 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={newFloor2} alt="" />
                  <span>1207 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={newFloor1} alt="" />
                  <span>1225 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk1} alt="" />
                  <span>1234 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk2} alt="" />
                  <span>1235 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk3} alt="" />
                  <span>1239 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk4} alt="" />
                  <span>1244 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk5} alt="" />
                  <span>1255 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk6} alt="" />
                  <span>1256 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk7} alt="" />
                  <span>1267 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk8} alt="" />
                  <span>1268 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk9} alt="" />
                  <span>1273 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk10} alt="" />
                  <span>1279 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk3} alt="" />
                  <span>1283 sq.ft.</span>
                </div>
                <img
                  src={right_arr}
                  style={{ width: "2.3rem" }}
                  className="rightArrow"
                  onClick={() => {
                    if (document.getElementById("idgoeshere")) {
                      document.getElementById("idgoeshere").scrollLeft =
                        document.getElementById("idgoeshere").scrollLeft + 450;
                    }
                  }}
                  alt="logo"
                />
              </div>
            ) : (
              <div className="comn_floor2" id="idgoesheree">
                {/* <div className='frst_floor'>
                                <div className='frst_frst'>
                                    <div><img className='floor_imagesss' src={floor5} /></div>
                                    <div><img className='floor_imagesss' src={floor6} /></div>
                                </div>
                                <div className='numberBold2'>1545 sq.ft.</div>
                            </div>
                            <div className='scnd_floor'>
                                <div className='frst_frst'>
                                    <div><img className='floor_imagesss' src={floor7} /></div>
                                    <div><img className='floor_imagesss' src={floor8} /></div>
                                </div>
                                <div className='numberBold2'>1697 sq.ft.</div>
                            </div> */}
                <img
                  src={left_arr}
                  style={{ marginRight: "1rem", width: "2.3rem" }}
                  className="leftArrow"
                  onClick={() => {
                    if (document.getElementById("idgoesheree")) {
                      document.getElementById("idgoesheree").scrollLeft =
                        document.getElementById("idgoesheree").scrollLeft - 450;
                    }
                  }}
                  alt="logo"
                />

                <div className="curr_image">
                  <img src={bhk31} alt="" />
                  <span>1545 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk32} alt="" />
                  <span>1583 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk33} alt="" />
                  <span>1586 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk34} alt="" />
                  <span>1607 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk35} alt="" />
                  <span>1608 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk36} alt="" />
                  <span>1675 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk37} alt="" />
                  <span>1680 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk38} alt="" />
                  <span>1694 sq.ft.</span>
                </div>
                <div className="curr_image">
                  <img src={bhk39} alt="" />
                  <span>1697 sq.ft.</span>
                </div>
                <img
                  src={right_arr}
                  style={{ width: "2.3rem" }}
                  className="rightArrow"
                  onClick={() => {
                    if (document.getElementById("idgoesheree")) {
                      document.getElementById("idgoesheree").scrollLeft =
                        document.getElementById("idgoesheree").scrollLeft + 450;
                    }
                  }}
                  alt="logo"
                />
              </div>
            )}
          </div>
          {/* <div
            className="learn text-white text-[16px] lm:text-[18px] font-medium"
            onClick={() => {
              setEnlargeBHK(true);
              // setBhkTop(window.scrollY);
              document.getElementById("body").style.overflow = "hidden";
              document.getElementById("lalala").style.filter =
                "brightness(0.5) blur(4px) drop-shadow(black 0px 0px 18px)";
              document.getElementById("lalala").style.transform = "scale(1.02)";
            }}
          >
            Click to enlarge
          </div> */}
          <div
            className="download_broch mt-[65px]"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Download Brochure
          </div>
        </div>
        {/* 7th part */}
        <div
          ref={locationRef}
          className="seventh_part flex flex-col px-[20px] md:px-[60px] lm:px-[100px] py-[40px] md:py-[60px] gap-[40px]"
        >
          <div className="items-center">
            <div
              className="amen_head text-center"
              style={{ marginBottom: "18px" }}
            >
              Connectivity
            </div>
            <div className="text-[#d6ad60] text-[32px] font-medium text-center">
              Convenient Location
            </div>
          </div>
          <div className="w-full flex flex-col ls:flex-row gap-[40px]  ls:gap-[53px] lm:gap-[120px]">
            <img
              className="w-full ls:max-w-[50%] ls:w-auto  ls:flex-grow"
              src={
                activeTab === "NearBy"
                  ? map2
                  : activeTab === "Education"
                  ? map3
                  : activeTab === "Hospitals"
                  ? map4
                  : activeTab === "Malls"
                  ? map5
                  : ""
              }
              alt=""
            />
            <div className="lol md:flex-grow md:flex md:flex-col">
              <div className="head_head">
                <div
                  onClick={() => {
                    setActiveTab("NearBy");
                  }}
                  className={`${
                    activeTab === "NearBy" ? "underhighlight" : "highlight"
                  }`}
                >
                  <div>Near By</div>
                  {activeTab === "NearBy" && (
                    <div
                      style={{
                        width: "65px",
                        height: "1.5px",
                        backgroundColor: "#d6ad60",
                      }}
                    ></div>
                  )}
                </div>
                <div
                  onClick={() => {
                    setActiveTab("Education");
                  }}
                  className={`${
                    activeTab === "Education" ? "underhighlight" : "highlight"
                  }`}
                >
                  <div>Education</div>
                  {activeTab === "Education" && (
                    <div
                      style={{
                        width: "82px",
                        height: "1.5px",
                        backgroundColor: "#d6ad60",
                      }}
                    ></div>
                  )}
                </div>
                <div
                  onClick={() => {
                    setActiveTab("Hospitals");
                  }}
                  className={`${
                    activeTab === "Hospitals" ? "underhighlight" : "highlight"
                  }`}
                >
                  <div>Hospitals</div>
                  {activeTab === "Hospitals" && (
                    <div
                      style={{
                        width: "74px",
                        height: "1.5px",
                        backgroundColor: "#d6ad60",
                      }}
                    ></div>
                  )}
                </div>
                <div
                  onClick={() => {
                    setActiveTab("Malls");
                  }}
                  className={`${
                    activeTab === "Malls" ? "underhighlight" : "highlight"
                  }`}
                >
                  <div>Malls</div>
                  {activeTab === "Malls" && (
                    <div
                      style={{
                        width: "46px",
                        height: "1.5px",
                        backgroundColor: "#d6ad60",
                      }}
                    ></div>
                  )}
                </div>
              </div>
              {activeTab === "NearBy" ? (
                <div className="frst_cmn">
                  <div className="convi_css">
                    <div style={{ gap: "16px", display: "flex" }}>
                      <div>
                        <img
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                          src={star}
                        />
                      </div>
                      <div>
                        <div className="overview">Garuda Badminton Academy</div>
                        <div className="numberBold">400m</div>
                      </div>
                    </div>
                    <div style={{ gap: "16px", display: "flex" }}>
                      <div>
                        <img
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                          src={star}
                        />
                      </div>
                      <div>
                        <div className="overview">Kowkoor Dargah</div>
                        <div className="numberBold">600m</div>
                      </div>
                    </div>
                  </div>
                  <div className="convi_css">
                    <div style={{ gap: "16px", display: "flex" }}>
                      <div>
                        <img
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                          src={star}
                        />
                      </div>
                      <div>
                        <div className="overview">Kapra Lake</div>
                        <div className="numberBold">900m</div>
                      </div>
                    </div>
                    <div style={{ gap: "16px", display: "flex" }}>
                      <div>
                        <img
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                          src={star}
                        />
                      </div>
                      <div>
                        <div className="overview">Indus Universal School</div>
                        <div className="numberBold">200km</div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : activeTab === "Education" ? (
                <div className="frst_cmn">
                  <div className="md:flex-grow md:flex md:flex-col md:justify-around">
                    <div style={{ gap: "16px", display: "flex" }}>
                      <div>
                        <img
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                          src={star}
                        />
                      </div>
                      <div>
                        <div className="overview">Indus Universal School</div>
                        <div className="numberBold">1.2 Km</div>
                      </div>
                    </div>
                    <div style={{ gap: "16px", display: "flex" }}>
                      <div>
                        <img
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                          src={star}
                        />
                      </div>
                      <div>
                        <div className="overview">HOLY ZION HIGH SCHOOL</div>
                        <div className="numberBold">1.6 km</div>
                      </div>
                    </div>
                    <div style={{ gap: "16px", display: "flex" }}>
                      <div>
                        <img
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                          src={star}
                        />
                      </div>
                      <div>
                        <div className="overview">
                          St.Joseph's Missionary School
                        </div>
                        <div className="numberBold">1.8 km</div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex-grow md:flex  md:flex-col md:justify-around">
                    <div style={{ gap: "16px", display: "flex" }}>
                      <div>
                        <img
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                          src={star}
                        />
                      </div>
                      <div>
                        <div className="overview">
                          Akshara International School, A S Rao Nagar
                        </div>
                        <div className="numberBold">4.5 Km</div>
                      </div>
                    </div>
                    {/* <div style={{ gap: "16px", display: "flex" }}>
                                            <div><img style={{ height: "20px", width: "20px" }} alt='' src={star} /></div>
                                            <div>
                                                <div className='overview'>BITS Pilani</div>
                                                <div className='numberBold'>7.8 Km</div>
                                            </div>
                                        </div> */}
                  </div>
                </div>
              ) : activeTab === "Hospitals" ? (
                <div className="frst_cmn">
                  <div className="md:flex-grow md:flex  md:flex-col md:justify-around">
                    <div style={{ gap: "16px", display: "flex" }}>
                      <div>
                        <img
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                          src={star}
                        />
                      </div>
                      <div>
                        <div className="overview">
                          Meenakshi Mother and Child Hospital
                        </div>
                        <div className="numberBold">1.6 Km</div>
                      </div>
                    </div>
                    <div style={{ gap: "16px", display: "flex" }}>
                      <div>
                        <img
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                          src={star}
                        />
                      </div>
                      <div>
                        <div className="overview">
                          Vanaja Diabetic & Multi-Specialty Hospital, A S Rao
                          Nagar
                        </div>
                        <div className="numberBold">4 km</div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex-grow md:flex  md:flex-col md:justify-around">
                    <div style={{ gap: "16px", display: "flex" }}>
                      <div>
                        <img
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                          src={star}
                        />
                      </div>
                      <div>
                        <div className="overview">Thatha Hospital</div>
                        <div className="numberBold">1.8 km</div>
                      </div>
                    </div>
                    <div style={{ gap: "16px", display: "flex" }}>
                      <div>
                        <img
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                          src={star}
                        />
                      </div>
                      <div>
                        <div className="overview">Prasad Hospitals</div>
                        <div className="numberBold">4.5 Km</div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : activeTab === "Malls" ? (
                <div className="frst_cmn">
                  <div className="md:flex-grow md:flex  md:flex-col md:justify-around">
                    <div style={{ gap: "16px", display: "flex" }}>
                      <div>
                        <img
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                          src={star}
                        />
                      </div>
                      <div>
                        <div className="overview">
                          Gayathri Reddy Traditional Designer Studio
                        </div>
                        <div className="numberBold">2.7 Km</div>
                      </div>
                    </div>
                    <div style={{ gap: "16px", display: "flex" }}>
                      <div>
                        <img
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                          src={star}
                        />
                      </div>
                      <div>
                        <div className="overview">Anutex Shopping Mall LLP</div>
                        <div className="numberBold">2.7 km</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ gap: "16px", display: "flex" }}>
                    <div>
                      <img
                        style={{ height: "20px", width: "20px" }}
                        alt=""
                        src={star}
                      />
                    </div>
                    <div>
                      <div className="overview">Reliance Digital</div>
                      <div className="numberBold">3.6 km</div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* 8th part */}
        <div
          ref={contactRef}
          className="eight_part bg-cover flex bg-center items-center justify-center ls:justify-between py-[40px] ls:py-[64px] px-[20px] ls:px-[60px] lm:px-[100px] lg:px-[120px] xl:px-[300px]"
        >
          {/* left part......... */}
          <div className="left_head">
            <div className="contact">
              <div className="contact_us">Contact us</div>
              <div className="call_back">Get a Call Back</div>
            </div>
            <div className="contact_scnd">
              <div className="flex">
                <div className="pt-[6px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.33268 3.33398H16.666C17.5865 3.33398 18.3327 4.08018 18.3327 5.00065V15.0007C18.3327 15.9212 17.5865 16.6673 16.666 16.6673H3.33268C2.41221 16.6673 1.66602 15.9212 1.66602 15.0007V5.00065C1.66602 4.08018 2.41221 3.33398 3.33268 3.33398ZM11.3743 12.8757L16.666 9.16732V7.41732L10.541 11.709C10.2153 11.9351 9.78343 11.9351 9.45768 11.709L3.33268 7.41732V9.16732L8.62435 12.8757C9.45018 13.4529 10.5485 13.4529 11.3743 12.8757Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div>
                  <div className="top">Email</div>
                  {/* <div className="bottom">contact@srivathsahomes.com</div> */}
                  <div className="bottom">thesterlingabode@gmail.com</div>
                </div>
              </div>
              <div className="flex">
                <div className="pt-[6px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M14.1667 17.5005C12.7886 17.4992 11.4319 17.1587 10.2167 16.5088L9.84167 16.3005C7.25063 14.9074 5.12641 12.7832 3.73333 10.1922L3.525 9.81715C2.85818 8.59498 2.50594 7.22605 2.5 5.8338V5.27547C2.49965 4.83127 2.67663 4.4053 2.99167 4.09213L4.4 2.6838C4.53678 2.54597 4.72984 2.47953 4.92247 2.50402C5.11509 2.5285 5.2854 2.64113 5.38333 2.8088L7.25833 6.02547C7.44794 6.35288 7.39319 6.76693 7.125 7.0338L5.55 8.60882C5.4192 8.73815 5.38873 8.93798 5.475 9.10048L5.76667 9.65048C6.8142 11.5908 8.40775 13.1815 10.35 14.2255L10.9 14.5255C11.0625 14.6117 11.2623 14.5812 11.3917 14.4505L12.9667 12.8755C13.2335 12.6073 13.6476 12.5526 13.975 12.7422L17.1917 14.6172C17.3593 14.7151 17.472 14.8854 17.4964 15.078C17.5209 15.2707 17.4545 15.4637 17.3167 15.6005L15.9083 17.0088C15.5952 17.3238 15.1692 17.5008 14.725 17.5005H14.1667Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div>
                  <div className="top">Phone</div>
                  {/* <div className="bottom">+91 9100008979</div> */}
                  <div className="bottom">+91 8712010208</div>
                </div>
              </div>
              <div className="flex">
                <div className="pt-[6px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10.0009 1.66602C6.67259 1.66602 3.96484 4.37377 3.96484 7.70202C3.96484 11.8325 9.36648 17.8963 9.59648 18.1523C9.81248 18.3929 10.1896 18.3925 10.4053 18.1523C10.6352 17.8963 16.0369 11.8325 16.0369 7.70202C16.0368 4.37377 13.3291 1.66602 10.0009 1.66602ZM10.0009 10.7389C8.32633 10.7389 6.96403 9.3766 6.96403 7.70202C6.96403 6.02747 8.32636 4.66517 10.0009 4.66517C11.6754 4.66517 13.0377 6.02751 13.0377 7.70205C13.0377 9.3766 11.6754 10.7389 10.0009 10.7389Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div>
                  <div className="top">Office</div>
                  <div className="bottom">Sainikpuri-Yapral, Hyderabad</div>
                </div>
              </div>
            </div>
            <div className="flex justify-center w-full ls:hidden">
              <div
                className="download_broch"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Enquiry Now
              </div>
            </div>
          </div>
          {/* right part........ */}
          <div className="formmmmsssssrr">
            <div className="formm">
              <div className="enquire">Enquire Now</div>
              <div>
                <input
                  className="formRow"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <input
                  className="formRow"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => {
                    handlePhoneChange(e);
                  }}
                />
              </div>
              <div>
                <input
                  className="formRow"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <div className="submit_btn" onClick={handleDownload}>
                Submit
              </div>
            </div>
          </div>
        </div>
        {/* 9th part */}
        <div className="ninth_part">
          <div>
            <img
              style={{
                height: "100px",
                width: "152px",
                marginTop: "12px",
                marginBottom: "16px",
              }}
              src={logoo}
              alt=""
            />
          </div>
          <div className="seccccc">
            A Premium Gated Community of Luxury Apartments
          </div>
          <div className="footer_heads">
            <span className="seccccc" onClick={scrollToOverview}>
              Overview
            </span>
            <span className="seccccc" onClick={scrollToAmenities}>
              Amenities
            </span>
            <span className="seccccc" onClick={scrollToGallery}>
              Gallery
            </span>
            <span
              className="seccccc"
              onClick={scrollToFloorPlan}
              style={{ textWrap: "nowrap" }}
            >
              Floor Plan
            </span>
            <span className="seccccc" onClick={scrollToLocation}>
              Location
            </span>
            <span className="seccccc" onClick={scrollToContact}>
              Contact
            </span>
          </div>
          <div
            style={{
              display: "flex",
              margin: "10px 0px 10px 0px",
              gap: "12px",
            }}
          >
            <div>
              <img src={facebook} alt="logo" />
            </div>
            <div>
              <img src={insta} alt="logo" />
            </div>
          </div>
          <div style={{ width: "94vw", border: "1px solid #ffffff" }}></div>
          <div
            className="seccccc"
            style={{ marginTop: "16px", marginBottom: "16px" }}
          >
            Copyright © 2024 Sterling Abode
          </div>
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose} placement="start">
        <Offcanvas.Body>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "2rem",
              }}
            >
              <img src={newLogo} alt="logo" />
            </div>
            <div>
              <div
                className="sidebar_topic"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  scrollToOverview();
                  handleClose();
                }}
              >
                Overview
              </div>
              <div
                className="sidebar_topic"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  scrollToAmenities();
                  handleClose();
                }}
              >
                Amenities
              </div>
              <div
                className="sidebar_topic"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  scrollToGallery();
                  handleClose();
                }}
              >
                Gallery
              </div>
              <div
                className="sidebar_topic"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  scrollToFloorPlan();
                  handleClose();
                }}
              >
                Floor Plan
              </div>
              <div
                className="sidebar_topic"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  scrollToLocation();
                  handleClose();
                }}
              >
                Location
              </div>
              <div
                className="sidebar_topic"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  scrollToContact();
                  handleClose();
                }}
              >
                Contact
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={showModall}
        dialogClassName="newclasssesss"
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ padding: "32px", height: "361px" }}>
          <div className="modal_border">
            <div
              className="cross_icon"
              onClick={() => {
                setShowModal(false);
                setEmail("");
                setPhone("");
                setName("");
              }}
            >
              <img src={Frame} alt="logo" />
            </div>
            <div>
              <div
                className="brochhh"
                style={{ fontFamily: "lora" }}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Download Brochure
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <input
                    className="formRow"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <input
                    className="formRow"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => {
                      handlePhoneChange(e);
                    }}
                  />
                </div>
                <div>
                  {" "}
                  <input
                    className="formRow"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
                {/* {!isValidEmail && (
                            <p style={{ color: 'red' }}>Please enter a valid email address</p>
                        )} */}
              </div>
              <div className="submit_btn" onClick={handleDownload}>
                Submit
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* successfullll modal */}
      <Modal
        show={showModall2}
        dialogClassName="newclasssesss2"
        onHide={handleCloseModal2}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ padding: "32px", height: "325px" }}>
          {/* <div className='modal_border'> */}
          <div className="comn">
            <div
              className="cross_icon2"
              onClick={() => {
                setShowModal2(false);
              }}
            >
              <img src={Frame} alt="logo" />
            </div>
            <div>
              <img src={tick} alt="logo" />
            </div>
            <div className="down" id="submit-done">Brochure Download Successful!</div>
            <div className="thnkyou">
              Thank you! You can also find the brochure in your inbox
            </div>
          </div>
          {/* </div> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AbodeMainLandingPage;
